import {
  IPricingTableResponse,
  IPricingTableInfoFormValues,
  IGetPricingTableResponse,
  IPricingTableFilters,
} from "interfaces/pricingTable";
import { requestServices } from "services/index";

const { marketCoreClient } = requestServices;

const createPricingTable = (data: IPricingTableInfoFormValues): Promise<IPricingTableResponse> => {
  return marketCoreClient.post("/api/v2/pricing-tables", data);
};

const updatePricingTable = (data: IPricingTableInfoFormValues): Promise<IPricingTableResponse> => {
  const { id, ...rest } = data;
  return marketCoreClient.patch(`/api/v2/pricing-tables/${id}`, rest);
};

const getPricingTables = (params: IPricingTableFilters): Promise<IGetPricingTableResponse> => {
  return marketCoreClient.get("/api/v2/pricing-tables", {
    params,
  });
};

export default {
  createPricingTable,
  updatePricingTable,
  getPricingTables,
};
