import { requestServices } from "../index";
import { IFeatureResponse, IFeatureFilters, IModuleFilters, IModuleResponse } from "../../interfaces/feature";

const { marketCoreClient } = requestServices;

const getFeatures = (params: IFeatureFilters): Promise<IFeatureResponse> => {
  return marketCoreClient.get("/api/v2/product-features", {
    params,
  });
};
const getModules = (params: IModuleFilters): Promise<IModuleResponse> => {
  return marketCoreClient.get("/api/v2/product-features/modules", {
    params,
  });
};
export default {
  getFeatures,
  getModules,
};
