import { notification } from "antd";
import { AxiosError } from "axios";
import { t } from "helpers/i18n";
import { userServices } from "services";
import { commonConstants } from "constants/index";

const { CUSTOM_ERROR_CODE } = commonConstants;

const handleResponseError = (error: AxiosError) => {
  const status = error && error.response && error.response.status;
  let message = null;
  switch (status) {
    case 401:
      userServices.logout();
      break;
    case 403:
      userServices.denyAccess();
      break;
    case 400:
      if (error.response && error.response.data) {
        const { data } = error.response;
        const code = data.code;
        if (CUSTOM_ERROR_CODE.includes(code)) message = t(`CustomError.${code}`);
        else message = data.message;
      }
      break;
    default:
      // Handle error message from API response
      if (error.response && error.response.data) {
        const { data } = error.response;
        message = data.message;
      }
      break;
  }
  notification.error({
    message: t("Error"),
    description: message || t("SomethingWentWrong"),
  });
};

export default {
  handleResponseError,
};
