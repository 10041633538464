const ROW_GUTTER = {
  xl: 48,
  lg: 32,
  md: 16,
  sm: 16,
  xs: 16,
};

const RESPONSIVE_COLUMN_LAYOUT = {
  xs: 24,
  md: 12,
  lg: 8,
};

const ONE_COL_LAYOUT = {
  span: 24,
};

const TWO_COL_LAYOUT = {
  ...RESPONSIVE_COLUMN_LAYOUT,
  lg: 12,
};

const THREE_COL_LAYOUT = {
  ...RESPONSIVE_COLUMN_LAYOUT,
};

const FOUR_COL_LAYOUT = {
  ...RESPONSIVE_COLUMN_LAYOUT,
  xl: 6,
};

const FIVE_COL_LAYOUT = {
  ...RESPONSIVE_COLUMN_LAYOUT,
  xl: 5,
};

const SIX_COL_LAYOUT = {
  ...RESPONSIVE_COLUMN_LAYOUT,
  xl: 4,
};

const INLINE_LAYOUT = {
  labelCol: {
    xl: { span: 8 },
    lg: { span: 24 },
    md: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xl: { span: 16 },
    lg: { span: 24 },
    md: { span: 24 },
    sm: { span: 24 },
  },
};

const INLINE_TAIL_LAYOUT = {
  wrapperCol: {
    xl: { offset: 8, span: 16 },
  },
};

const HORIZONTAL_LAYOUT = {
  labelCol: {
    xxl: { span: 6 },
    xl: { span: 8 },
    lg: { span: 8 },
    md: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xxl: { span: 10 },
    xl: { span: 12 },
    lg: { span: 16 },
    md: { span: 24 },
    sm: { span: 24 },
  },
};

const HORIZONTAL_HALF_LAYOUT = {
  labelCol: {
    xxl: { span: 18 },
    xl: { span: 16 },
    lg: { span: 16 },
    md: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xxl: { span: 6 },
    xl: { span: 8 },
    lg: { span: 8 },
    md: { span: 24 },
    sm: { span: 24 },
  },
};

const HORIZONTAL_TAIL_LAYOUT = {
  wrapperCol: {
    xxl: { offset: 6, span: 10 },
    xl: { offset: 8, span: 12 },
    lg: { offset: 8, span: 16 },
  },
};

const POPUP_ROW_GUTTER = 8;

const VERTICAL_LAYOUT = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export default {
  RESPONSIVE_COLUMN_LAYOUT,
  ROW_GUTTER,
  ONE_COL_LAYOUT,
  TWO_COL_LAYOUT,
  THREE_COL_LAYOUT,
  FOUR_COL_LAYOUT,
  FIVE_COL_LAYOUT,
  SIX_COL_LAYOUT,
  INLINE_LAYOUT,
  INLINE_TAIL_LAYOUT,
  HORIZONTAL_LAYOUT,
  HORIZONTAL_HALF_LAYOUT,
  HORIZONTAL_TAIL_LAYOUT,
  POPUP_ROW_GUTTER,
  VERTICAL_LAYOUT,
};
