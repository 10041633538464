import { notification, Modal } from "antd";
import { ModalFuncProps } from "antd/lib/modal";
import { t } from "helpers/i18n";
import React from "react";

const promptSuccess = (message: string, title: string = t("Success")) => {
  notification.success({
    message: title,
    description: message,
  });
};

const promptError = (message: string, title: string = t("Error")) => {
  notification.error({
    message: title,
    description: message,
  });
};

const promptWarning = (message: string | React.ReactNode, title: string = t("Warning")) => {
  notification.warning({
    message: title,
    description: message,
  });
};

const promptInfo = (message: string, title: string = t("Notification")) => {
  notification.info({
    message: title,
    description: message,
  });
};

const showConfirmModal = (props: ModalFuncProps) => {
  Modal.confirm({
    title: t("RequestConfirmation"),
    maskClosable: true,
    content: t("AreYouSureToContinue"),
    okText: t("Confirm"),
    cancelText: t("Cancel"),
    ...props,
  });
};

export default {
  promptSuccess,
  promptError,
  promptWarning,
  promptInfo,
  showConfirmModal,
};
