import { PaginationProps } from "antd";
import { commonConstants } from "constants/index";
import { IPagination } from "interfaces";
import { IPricingTable, IPricingTableFilters } from "interfaces/pricingTable";
import { useEffect, useState } from "react";
import { pricingTablesServices } from "services";

const { DEFAULT_PAGINATION, DEFAULT_SIZE_CHANGER } = commonConstants;

const useListPricingTablesData = (defaultParams: IPricingTableFilters) => {
  const [queryParams, setQueryParams] = useState(defaultParams);
  const [fetching, setFetching] = useState(false);
  const [pricingTables, setPricingTables] = useState<IPricingTable[]>([]);
  const [pagination, setPagination] = useState<IPagination>({
    ...DEFAULT_PAGINATION,
    ...DEFAULT_SIZE_CHANGER,
  });

  const getPricingTable = async (params: IPricingTableFilters) => {
    try {
      setFetching(true);
      const res = await pricingTablesServices.getPricingTables(params);
      if (res.data) {
        const { data } = res.data;
        const { pagination, pricingTables } = data;
        const { page, pageSize, total } = pagination;
        setPricingTables(pricingTables || []);
        setPagination({
          ...pagination,
          current: page,
          pageSize,
          total,
        });
      }
    } finally {
      setFetching(false);
    }
  };

  const handleTableChange = (pager: PaginationProps) => {
    const { current, pageSize } = pager;
    const newQueryParams = {
      ...queryParams,
      page: current,
      pageSize,
    };
    setQueryParams(newQueryParams);
  };

  useEffect(() => {
    if (queryParams && queryParams.appId) {
      getPricingTable(queryParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const handleRefresh = () => setQueryParams(prev => ({ ...prev, page: 1 }));

  return {
    queryParams,
    setQueryParams,
    pricingTables,
    fetching,
    handleRefresh,
    pagination,
    handleTableChange,
  };
};

export default {
  useListPricingTablesData,
};
