import { IMap } from "interfaces";
import _ from "lodash";

const multiple = <T1, T2 extends any>(a1: Array<T1>, a2: Array<T2>, merger: (o1: T1, o2: T2) => any) => {
  if (a1.length === 0) return a2;
  if (a2.length === 0) return a1;
  return a1.flatMap(v1 => a2.map(v2 => merger(v1, v2)));
};

const sumByKey = (data: Array<any>, key: string) => {
  return data.reduce((acc, cur) => acc + cur[key], 0);
};

const swapArrayElements = (array: any, index: number, newIndex: number) => {
  if (newIndex < 0 || newIndex >= array.length) return;
  const temp = array[index];
  array[index] = array[newIndex];
  array[newIndex] = temp;
};

const groupBy = <T>(records: T[], indexer: string | string[] | ((record: T) => string)): IMap<T[]> => {
  const getIndex =
    typeof indexer === "function"
      ? indexer
      : (record: T) => {
          return _.flatten([indexer])
            .map(field => record[field as keyof T])
            .join();
        };

  const groupTable = records.reduce((acc: IMap<T[]>, cur: T) => {
    const id = getIndex(cur);
    (acc[id] = acc[id] || []).push(cur);
    return acc;
  }, {});

  return groupTable;
};

const unionData = <T extends {}>(list: Partial<T>[], keyField: string) => {
  const table = list.reduce((acc, cur) => {
    const key = _.get(cur, keyField);
    acc[key] = { ...acc[key], ...cur };
    return acc;
  }, {} as IMap<T>);

  return Object.values(table);
};

/**
 * Return an new array that replace all elements which matched `newElement`.
 * @param array The source array
 * @param matcher A function or object to determine the element need to be replaced or not
 * @param newElement The element to replace
 * */
const replaceArrayElements = <T extends {}>(
  array: T[],
  matcher: Partial<T> | ((element: T) => boolean),
  newElement: T
) => {
  const isMatched = typeof matcher === "function" ? matcher : (element: T) => _.isMatch(element, matcher);

  return array.map(element => (isMatched(element) ? { ...newElement } : element));
};

const getArrayIds = (data: Array<{ id: number }>) => data?.map(item => item.id);

const getTextByKey = (
  data: IMap<number | string>[],
  values: Array<number | string>,
  key = "id",
  prefixKey?: string
) => {
  return data
    .filter(item => values.includes(item[key]))
    .map(filteredItem => (prefixKey ? `[${filteredItem[prefixKey]}] ${filteredItem.name}` : filteredItem.name))
    .join(", ");
};

type TruthyValue<T> = Exclude<T, "" | 0 | null | undefined | false>;
/**
 * Returns list of truthy values which aren't `''`, `0`, `null`, `false` and `undefined`.
 */
const getTruthyValues = <T extends any>(values: T[]) => {
  return values.filter(Boolean) as TruthyValue<T>[];
};

export default {
  multiple,
  groupBy,
  sumByKey,
  swapArrayElements,
  replaceArrayElements,
  unionData,
  getArrayIds,
  getTextByKey,
  getTruthyValues,
};
