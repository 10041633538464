import { IModuleFilters, IModule } from "interfaces/feature";
import { useEffect, useState } from "react";
import { featuresServices } from "services";

const useListModulesData = (defaultParams: IModuleFilters) => {
  const [queryParams, setQueryParams] = useState(defaultParams);
  const [fetching, setFetching] = useState(false);
  const [modules, setModules] = useState<IModule[]>([]);

  const getModules = async (params: IModuleFilters) => {
    try {
      setFetching(true);
      const res = await featuresServices.getModules(params);

      if (res.data) {
        const { data } = res.data;
        if (data) {
          const { productModules } = data;
          setModules(productModules || []);
        }
      }
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    if (queryParams && queryParams.appId) {
      getModules(queryParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  return {
    queryParams,
    setQueryParams,
    modules,
    fetching,
  };
};

export default {
  useListModulesData,
};
