import { PaginationProps } from "antd";
import { commonConstants } from "constants/index";
import { IPagination } from "interfaces";
import { IMmApp, IMmAppFilters } from "interfaces/mmApp";
import { useEffect, useState } from "react";
import { mmAppsServices } from "services";

const { DEFAULT_PAGINATION, DEFAULT_SIZE_CHANGER } = commonConstants;

const useListMmAppsData = (defaultParams: IMmAppFilters, needGetAll = false) => {
  const [queryParams, setQueryParams] = useState(defaultParams);
  const [fetching, setFetching] = useState(false);
  const [mmApps, setMmApps] = useState<IMmApp[]>([]);
  const [pagination, setPagination] = useState<IPagination>({
    ...DEFAULT_PAGINATION,
    ...DEFAULT_SIZE_CHANGER,
  });

  const getMmApp = async (params: IMmAppFilters) => {
    try {
      setFetching(true);
      const res = await mmAppsServices.getMmApps(params);
      if (res.data) {
        const { data } = res.data;
        if (data) {
          const {
            apps,
            pagination: { total, page, pageSize },
          } = data;
          setMmApps([...apps]);
          setPagination({
            ...pagination,
            current: page,
            pageSize,
            total,
          });
        }
      }
    } finally {
      setFetching(false);
    }
  };

  const getAllMmApp = async (params: IMmAppFilters) => {
    try {
      setFetching(true);
      params.page = 1;
      const totalItems = [];
      while (true) {
        const res = await mmAppsServices.getMmApps(params);
        if (res.data) {
          const { data } = res.data;
          if (data) {
            const {
              apps,
              pagination: { total },
            } = data;
            totalItems.push(...apps);
            if (totalItems.length >= total) break;
            params.page++;
          }
        }
      }
      setMmApps(totalItems);
    } finally {
      setFetching(false);
    }
  };

  const handleTableChange = (pager: PaginationProps) => {
    const { current, pageSize } = pager;
    const newQueryParams = {
      ...queryParams,
      page: current,
      pageSize,
    };
    setQueryParams(newQueryParams);
  };

  useEffect(() => {
    if (needGetAll) {
      getAllMmApp(queryParams);
    } else {
      getMmApp(queryParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, needGetAll]);

  const handleRefresh = () => setQueryParams(prev => ({ ...prev, page: 1 }));

  return {
    mmApps,
    fetching,
    handleRefresh,
    pagination,
    handleTableChange,
  };
};

export default {
  useListMmAppsData,
};
