import accounting from "accounting";

accounting.settings.currency.format = "%v";
accounting.settings.currency.precision = 0;

const formatPrice = (value: any, userTyping?: boolean, precision?: number) => {
  if (userTyping) return value;
  if (value === null || value === undefined) return "";
  if (precision) return accounting.formatMoney(value, { precision });
  return accounting.formatMoney(value);
};

const formatNumber = (value: any, options?: Intl.NumberFormatOptions) => {
  if (value === null || value === undefined) return "";
  return new Intl.NumberFormat("en-EN", options).format(value);
};

const convertSecondsToDays = (seconds: number): number | string => {
  const res = Math.floor(seconds / (60 * 60 * 24));
  return res >= 1 ? res : "< 1";
};

const convertDaysToSeconds = (days: number): number => {
  return days * 60 * 60 * 24;
};

export default {
  formatPrice,
  formatNumber,
  convertSecondsToDays,
  convertDaysToSeconds,
};
