import { dateTimeConstants } from "constants/index";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const { DATE_TIME_SLASH_FORMAT, DATE_SLASH_FORMAT } = dateTimeConstants;

/**
 * Example inputs:
 *
 * * 2020-10-06T04:45:15Z (no milliseconds; single letter Z means UTC+0)
 * * 2012-01-26T13:51:50.417-07:00 (with UTC-7)
 * * 2020-10-06 06:59:42+07:00 (no delimiter, with UTC+7)
 * */
const formatDate = (time: string) => {
  return dayjs(time).format(DATE_SLASH_FORMAT);
};

/**
 * Example inputs:
 *
 * * 2020-10-06T04:45:15Z (no milliseconds; single letter Z means UTC+0)
 * * 2012-01-26T13:51:50.417-07:00 (with UTC-7)
 * * 2020-10-06 06:59:42+07:00 (no delimiter, with UTC+7)
 * */
const formatDateTime = (time: string) => {
  return dayjs(time).format(DATE_TIME_SLASH_FORMAT);
};

const beforeToday = (date: dayjs.Dayjs | boolean): boolean => {
  return date && date < dayjs().startOf("day");
};

export default {
  formatDate,
  formatDateTime,
  beforeToday,
};
