import { HomeOutlined } from "@ant-design/icons";
import { routeConstants } from "constants/index";
import { t } from "helpers/i18n";
import { lazy } from "react";
import mmAppSubmenus from "./mmApp";
import pricingTableSubmenus from "./pricingTable";

const { PATH } = routeConstants;

// App pages
const Home = lazy(() => import("containers/Home"));

/*
 * If route has children, it's a parent menu (not link to any pages)
 * You can change permissions to your IAM's permissions
 */
const routes = [
  // This is a menu/route which has no children (sub-menu)
  {
    exact: true,
    path: PATH.HOME,
    name: t("Home"),
    component: Home,
    icon: HomeOutlined,
  },
  // This is a parent menu which has children (sub-menu) and requires catalog:brand:X permission to display
  // X maybe read/create/update/delete...
  ...mmAppSubmenus,
  ...pricingTableSubmenus,
];

export default routes;
