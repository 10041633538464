import { IFeatureFilters, IFeature } from "interfaces/feature";
import { useEffect, useState } from "react";
import { featuresServices } from "services";

const useListFeaturesData = (defaultParams: IFeatureFilters) => {
  const [queryParams, setQueryParams] = useState(defaultParams);
  const [fetching, setFetching] = useState(false);
  const [features, setFeatures] = useState<IFeature[]>([]);

  const getFeature = async (params: IFeatureFilters) => {
    try {
      setFetching(true);
      const res = await featuresServices.getFeatures(params);

      if (res.data) {
        const { data } = res.data;
        if (data) {
          const { productFeatures } = data;
          setFeatures(productFeatures || []);
        }
      }
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    if (queryParams && queryParams.appId) {
      getFeature(queryParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  return {
    queryParams,
    setQueryParams,
    features,
    fetching,
  };
};

export default {
  useListFeaturesData,
};
