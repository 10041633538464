import regexConstants from "constants/regex";
import { InputNumberProps } from "antd/es/input-number";
import { IPasswordPolicy } from "interfaces";

const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase();
};

const capitalJoin = (...args: Array<string>) => {
  return capitalize(args.join(" "));
};

// refer this: https://stackoverflow.com/questions/2254185/regular-expression-for-formatting-numbers-in-javascript
const formatNumber: InputNumberProps["formatter"] = value => {
  const strValue = String(value);
  const [integerPart, decimalPart = ""] = strValue.split(".");
  return integerPart.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.") + (strValue.includes(".") ? `,${decimalPart}` : "");
};

const parseNumber: InputNumberProps["parser"] = value => {
  return `${value}`
    .replace(/\.$/, ",")
    .replace(regexConstants.NUMBER_THOUSAND_SEPARATOR_REGEX, "")
    .replace(",", ".");
};

const parseCodes = (text: string) => {
  const separatorRegex = /[^a-zA-Z0-9_.]+/;
  return text.split(separatorRegex).filter(code => code);
};

const isPositiveNumber = (value: string) => {
  return /^\d+$/.test(value);
};

const getIntValIfValid = (value: string) => {
  return isPositiveNumber(value) ? parseInt(value) : value;
};

// change accent to non accent vietnamese, support unicode
const noAccentVietnamese = (data: string) => {
  if (!data) return data;

  data = data.replace(/[àáạảãâầấậẩẫăằắặẳẵ]/g, "a");
  data = data.replace(/[ÀÁẠẢÃĂẰẮẶẲẴÂẦẤẬẨẪ]/g, "A");
  data = data.replace(/[èéẹẻẽêềếệểễ]/g, "e");
  data = data.replace(/[ÈÉẸẺẼÊỀẾỆỂỄ]/g, "E");
  data = data.replace(/[òóọỏõôồốộổỗơờớợởỡ]/g, "o");
  data = data.replace(/[ÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠ]/g, "O");
  data = data.replace(/[ìíịỉĩ]/g, "i");
  data = data.replace(/[ÌÍỊỈĨ]/g, "I");
  data = data.replace(/[ùúụủũưừứựửữ]/g, "u");
  data = data.replace(/[ƯỪỨỰỬỮÙÚỤỦŨ]/g, "U");
  data = data.replace(/[ỳýỵỷỹ]/g, "y");
  data = data.replace(/[ỲÝỴỶỸ]/g, "Y");
  data = data.replace(/[đ]/g, "d");
  data = data.replace(/[Đ]/g, "D");

  return data;
};

// Slug URL Key
const slugURLKey = (data: string) => {
  data = data
    .replace(
      /[^ 0-9a-zA-ZáàảãạÁÀẢÃẠâÂấẤầẦẩẨẫẪậẬăĂắẮằẰẳẲẵẴặẶđĐéÉèÈẻẺẽẼẹẸêÊếẾềỀểỂễỄệỆíÍìÌỉỈĩĨịỊóÓòÒỏỎõÕọỌôÔốỐồỒổỔỗỖộỘơƠớỚờỜởỞỡỠợỢúÚùÙủỦũŨụỤưƯứỨừỪửỬữỮựỰýÝỳỲỷỶỹỸỵỴ]/g,
      ""
    )
    .trim();
  const urlKey = noAccentVietnamese(data)
    .replace(/ /g, "-")
    .toLowerCase();
  return urlKey;
};

const normalizeSeparator = (value: string) => {
  const trimRegex = /(^,+)|(,$)/g;
  return value
    .split(regexConstants.SEPARATOR_REGEX)
    .join(",")
    .replace(trimRegex, "");
};

const areSame = (s1: string | undefined, s2: string | undefined) => {
  return s1 && s2 && s1.toLowerCase() === s2.toLowerCase();
};

const updateQueryStringParameter = (uri: string, key: string, value: string | number) => {
  const re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  const separator = uri.indexOf("?") !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    return uri + separator + key + "=" + value;
  }
};

const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const alphabetDigitString = (str: string) => str.replace(/[^A-Za-z0-9]/g, "");

/**
 * Get ellipsis text with limit for an array of values
 * @param data array of values (maybe string or number)
 * @param limit max number of value will be displayed (default 5)
 */
const getEllipsisText = (data: Array<string | number>, limit = 5) => {
  const result = data.slice(0, limit).join(", ");
  return data.length > limit ? `${result}...` : result;
};

const isBooleanString = (booleanValue: boolean, value?: string) => String(booleanValue) === String(value).toLowerCase();

/**
 * Trim all sides of string. That means:
 * + Remove leading and trailing whitespaces
 * + Remove duplicate whitespaces in the middle
 * @param text the text to be trim
 */
const trimAll = (text: string) => {
  return text.trim().replace(/\s+/g, " ");
};

/**
 * Returns a new hex that has `#` at the head and tranformed to uppercase
 */
const normalizeHexCode = (value: string) => {
  return `#${value.replace("#", "").toUpperCase()}`;
};

const parseJson = <T extends any>(jsonString: string, defaultValue: T = undefined as T): T => {
  try {
    return JSON.parse(jsonString);
  } catch {
    return defaultValue;
  }
};

const buildPasswordRegex = (passwordPolicy: IPasswordPolicy) => {
  const { min_length, number, uppercase, special } = passwordPolicy;
  const PASSWORD_REGEX_STRING = "^(?=.*[A-Z]{uppercase,})(?=.*[0-9]{number,})(?=.*[\\W_]{special,}).{min_length,}$";
  // Got from https://regex101.com/r/JWFsoW/1
  return new RegExp(
    PASSWORD_REGEX_STRING.replace("min_length", `${min_length}`)
      .replace("number", `${number}`)
      .replace("uppercase", `${uppercase}`)
      .replace("special", `${special}`)
  );
};

export default {
  capitalize,
  capitalJoin,
  isPositiveNumber,
  getIntValIfValid,
  noAccentVietnamese,
  slugURLKey,
  normalizeSeparator,
  areSame,
  updateQueryStringParameter,
  isJsonString,
  formatNumber,
  parseNumber,
  parseCodes,
  alphabetDigitString,
  getEllipsisText,
  isBooleanString,
  trimAll,
  normalizeHexCode,
  parseJson,
  buildPasswordRegex,
};
