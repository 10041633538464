import { t } from "helpers/i18n";

const IAM_SCOPES = [
  "openid",
  "profile",
  "read:permissions",
  // You can add more scopes (granted services) here
  "catalog",
  "app-admin-market-bff",
  "market-core",
];

const DEFAULT_PAGINATION = {
  page: 1,
  pageSize: 10,
};

const PAGE_SIZE_OPTIONS = [10, 15, 20, 50, 100];

const DEFAULT_SIZE_CHANGER = {
  showSizeChanger: true,
  pageSizeOptions: PAGE_SIZE_OPTIONS.map(size => String(size)),
  locale: {
    items_per_page: t("RecordPerPage"),
  },
};

const DEFAULT_FILTERS = {
  LICENSE_PLAN: {
    page: 1,
    pageSize: 10,
  },
  MM_APP: {
    page: 1,
    pageSize: 10,
    developerId: 1,
  },
  PRICING_TABLE: {
    page: 1,
    pageSize: 10,
  },
};

const CUSTOM_ERROR_CODE = [10050, 10051];

export default {
  IAM_SCOPES,
  ALL: "all",
  TABLET_WIDTH: 768,
  DEFAULT_PAGINATION,
  DEFAULT_FILTERS,
  DEFAULT_SIZE_CHANGER,
  CUSTOM_ERROR_CODE,
};
