import { requestServices } from "../index";
import { IMmAppResponse, IMmAppFilters, IMmUpsertApp } from "../../interfaces/mmApp";

const { marketCoreClient } = requestServices;

const getMmApps = (params: IMmAppFilters): Promise<IMmAppResponse> => {
  return marketCoreClient.get("/api/v2/apps", {
    params,
  });
};
const upsertMmApp = (data: IMmUpsertApp): Promise<IMmAppResponse> => {
  return marketCoreClient.post("/api/v2/apps", data);
};
export default {
  getMmApps,
  upsertMmApp,
};
