import { AppstoreOutlined } from "@ant-design/icons";
import { t } from "helpers/i18n";
import { lazy } from "react";

import { routeConstants } from "constants/index";

const { PATH } = routeConstants;

//Pages
const MmAppList = lazy(() => import("containers/MmApp/MmAppList"));

export default [
  // Parent menu
  {
    exact: true,
    path: PATH.APPLICATION_ROOT,
    name: t("Application"),
    icon: AppstoreOutlined,
    children: ["/application/list", PATH.APPLICATION_PRICING_TABLE.LIST, PATH.APPLICATION_PRICING_TABLE.LICENSE_PLAN],
  },

  // Sub menu
  {
    exact: true,
    path: PATH.APPLICATION_MM_APP.LIST,
    name: t("ListMMApplication"),
    component: MmAppList,
  },
];
