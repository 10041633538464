const APPLICATION_PATH = {
  APPLICATION_ROOT: "/application",
  APPLICATION_MM_APP: {
    LIST: "/application/list",
  },
  APPLICATION_LICENSE_PLAN: {
    LIST: "/application/license-plan",
  },
  APPLICATION_PRICING_TABLE: {
    LIST: "/application/pricing-table",
    LICENSE_PLAN: "/application/license-plan",
  },
};

const PATH = {
  HOME: "/",
  ...APPLICATION_PATH,
};

export default {
  PATH,
};
