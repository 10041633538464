import { IModule } from "./../interfaces/feature";
import { IFeature } from "interfaces/feature";

const groupFeatureByModule = (modules: IModule[], features: IFeature[]) => {
  const moduleName: Record<string, string> = { NoModule: "NoModule" };
  for (const module of modules) {
    moduleName[module.moduleCode] = module.name;
  }

  return features.reduce((res: any, feature: IFeature) => {
    let { moduleCode } = feature;
    if (moduleCode === undefined) {
      moduleCode = "NoModule";
    }
    if (!res[moduleCode]) {
      res[moduleCode] = {
        name: moduleName?.hasOwnProperty(moduleCode) ? moduleName[moduleCode] : moduleCode,
        children: [],
      };
    }
    res[moduleCode].children.push(feature);
    return res;
  }, {});
};

const buildFeatureTree = (modules: Record<string, any>) => {
  const tree = [];

  for (const [moduleCode, data] of Object.entries(modules)) {
    const moduleObject = {
      title: data.name + " (" + moduleCode + ")",
      key: moduleCode,
      children: data.children.map((feature: IFeature) => ({
        title: feature.name + " (" + feature.featureCode + ")",
        key: feature.featureCode,
      })),
    };

    if (moduleCode === "NoModule") {
      tree.push(...moduleObject.children);
    } else {
      tree.push(moduleObject);
    }
  }

  return tree;
};

export default {
  groupFeatureByModule,
  buildFeatureTree,
};
