import { HomeOutlined, LogoutOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, ButtonProps } from "antd";
import { routeConstants } from "constants/index";
import { userHelpers } from "helpers";
import { t } from "helpers/i18n";
import React from "react";
import { Link } from "react-router-dom";

const { PATH } = routeConstants;
const { logout } = userHelpers;

export type SharedButtonProps = ButtonProps & { contentName?: string };

/* Common Buttons */
export const DefaultButton: React.FC<SharedButtonProps> = ({ children, ...rest }) => (
  <Button {...rest}>{children}</Button>
);

export const PrimaryButton: React.FC<SharedButtonProps> = ({ children, ...rest }) => (
  <Button type="primary" {...rest}>
    {children}
  </Button>
);

/* Customized Buttons */
export const AddButton: React.FC<SharedButtonProps> = ({ children = t("AddNew"), ...rest }) => (
  <PrimaryButton data-testid="btn-add" icon={<PlusOutlined />} {...rest}>
    {children}
  </PrimaryButton>
);

export const CancelButton: React.FC<SharedButtonProps> = ({ children = t("Cancel"), ...rest }) => (
  <DefaultButton data-testid="btn-cancel" {...rest}>
    {children}
  </DefaultButton>
);

export const BackToHomeButton: React.FC<SharedButtonProps> = props => (
  <Link to={PATH.HOME}>
    <PrimaryButton icon={<HomeOutlined />} {...props}>
      {t("BackToHome")}
    </PrimaryButton>
  </Link>
);

export const LogoutButton: React.FC<ButtonProps> = props => (
  <Button icon={<LogoutOutlined />} onClick={logout} {...props}>
    {t("Logout")}
  </Button>
);
