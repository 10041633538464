import { StoreContext } from "contexts";
import userHelpers from "helpers/user";
import { IRoute } from "interfaces";
import { useContext, useEffect, useState } from "react";
import { userServices } from "services";
import { IUserDetailsInfo } from "teko-oauth2";

const useUserInfo = () => {
  const [currentUser, setCurrentUser] = useState<IUserDetailsInfo>();

  const getFullUserInfo = async () => {
    const fullUserInfo = await userServices.getFullUserInfo();
    setCurrentUser(fullUserInfo);
  };

  useEffect(() => {
    getFullUserInfo();
  }, []);

  return { currentUser };
};

const useAuthorizationData = (items: IRoute[]) => {
  const { currentUser } = useContext(StoreContext);

  // Get navigation which match permissions to build menu
  const filteredNavigation = userHelpers.filterHasPermissions(items, currentUser.permissions);

  // Only get routes which is link to a component
  const filteredRoutes = filteredNavigation.filter(item => !item.children && item.component);

  return {
    filteredNavigation,
    filteredRoutes,
  };
};

export default {
  useUserInfo,
  useAuthorizationData,
};
