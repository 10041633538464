import _ from "lodash";
import stringHelpers from "helpers/string";
import { t, tFieldIsRequired } from "helpers/i18n";
import { FormItemProps, RuleObject, Rule } from "antd/lib/form";
import { IMap, IPasswordPolicy } from "interfaces";
import { regexConstants } from "constants/index";
import { TextAreaProps } from "antd/es/input";
import { NamePath } from "antd/es/form/interface";

const { getIntValIfValid, normalizeSeparator } = stringHelpers;

const resetFilters = (formValues: any, setFieldsValue: any, resetFields: any) => {
  resetFields();
  Object.keys(formValues).forEach(key => {
    setFieldsValue({ [key]: undefined });
  });
};

/**
 * Get form initial values for filter from query string
 * @param queryParams query string form url
 * @param multipleSelectKeys keys with multiple value
 * @param keepStringFormatKeys keys want to keep string format
 * @returns filter form values
 */
const getFormInitialValues = (
  queryParams: any,
  multipleSelectKeys?: string[],
  keepStringFormatKeys?: IMap<boolean>
) => {
  const res: any = { ...queryParams };
  Object.entries(queryParams).forEach(([key, value]: any) => {
    // Multiple select field
    if (multipleSelectKeys && multipleSelectKeys.includes(key)) {
      res[key] = value?.split(",").map((i: string) => (keepStringFormatKeys?.[key] ? i : getIntValIfValid(i)));
    }
    // Other field (select & text)
    else {
      const stringVal = `${value}`;
      res[key] =
        (stringVal.length > 1 && stringVal.startsWith("0")) || keepStringFormatKeys?.[key]
          ? value
          : getIntValIfValid(value);
    }
  });
  return res;
};

const processNewFormValues = (queryParams: any, formValues: any, normalizeSeparatorKeys?: string[]) => {
  const res: any = { ...queryParams };
  Object.entries(formValues).forEach(([key, value]: any) => {
    const newValue = value === 0 || value ? value.toString().trim() : value;
    if (newValue && !!newValue.length) {
      res[key] =
        normalizeSeparatorKeys && normalizeSeparatorKeys.includes(key) ? normalizeSeparator(newValue) : newValue;
    } else {
      delete res[key];
    }
  });
  return res;
};

const getDiffInfo = (array: Array<any>, oldInfo: any) => {
  return array.reduce((result: any, [key, value]: any) => {
    if ((typeof value !== "undefined" || typeof oldInfo[key] !== "undefined") && !_.isEqual(value, oldInfo[key])) {
      result[key] = value;
    }
    return result;
  }, {});
};

const getShouldUpdate = (field: string, dependencyMap: IMap<string[]>): FormItemProps["shouldUpdate"] => (
  prevValues,
  nextValues
) => {
  const deps = [field, ...(dependencyMap[field] || [])];
  return deps.some(dep => prevValues[dep] !== nextValues[dep]);
};

const getShouldUpdateByNamePath = (...namePaths: NamePath[]) => (prev: object, next: object) =>
  namePaths.some(namePath => _.get(prev, namePath) !== _.get(next, namePath));

/* eslint-disable no-template-curly-in-string */
const getConfigValidateMessages = () => ({
  required: tFieldIsRequired("${label}"),
  whitespace: tFieldIsRequired("${label}"),
  number: {
    range: t("FieldMustBeInRange", {
      field: "${label}",
      min: "${min}",
      max: "${max}",
    }),
    min: t("FieldMustBeAtLeastValue", {
      field: "${label}",
      value: "${min}",
    }),
  },
  string: {
    max: t("FieldMaxLength", {
      field: "${label}",
      maxLength: "${max}",
    }),
    range: t("FieldMustBeInRangeCharacter", {
      field: "${label}",
      min: "${min}",
      max: "${max}",
    }),
    len: t("FieldMustHaveExactlyLength", {
      field: "${label}",
      length: "${len}",
    }),
  },
  types: {
    number: t("FieldInvalidFormat", { field: "${label}" }),
  },
  pattern: {
    mismatch: t("FieldInvalidFormat", { field: "${label}" }),
  },
});

const getNumberRule = (config?: Omit<RuleObject, "type">): Rule => ({
  type: "number",
  ...config,
});

const getTextRule = (config: RuleObject): Rule => config;

const getColorRule = (config?: Omit<Rule, "type" | "pattern">): Rule => ({
  pattern: regexConstants.HEX_COLOR_REGEX,
  ...config,
});
const getArrayRule = (config: Omit<RuleObject, "type">): Rule => ({
  type: "array",
  ...config,
});

const getEmailRule = (config?: Omit<RuleObject, "type">) => ({
  pattern: regexConstants.EMAIL_REGEX,
  message: t("FieldInvalidFormat", { field: t("Email") }),
  ...config,
});

const getPhoneRule = (config?: Omit<RuleObject, "type">) => ({
  pattern: regexConstants.PHONE_REGEX,
  message: t("FieldInvalidFormat", { field: t("PhoneNumber") }),
  ...config,
});

const getPasswordRule = (passwordPolicy: IPasswordPolicy) => {
  const { min_length, number, uppercase, special } = passwordPolicy;
  const passwordRegex = stringHelpers.buildPasswordRegex(passwordPolicy);
  return {
    pattern: passwordRegex,
    message: `${t("FieldMinLength", {
      field: t("Password"),
      minLength: min_length,
    })}, ${t("FieldHaveAtLeast")} ${t("SpecialCharacter", {
      amount: special,
    })}, ${t("UppercaseCharacter", {
      amount: uppercase,
    })} & ${t("NumberCharacter", {
      amount: number,
    })}`,
  };
};

const getCharacterCount = (): TextAreaProps["showCount"] => {
  return {
    formatter: ({ count }) => `${count} ${t("characters")}`,
  };
};

export default {
  getTextRule,
  getNumberRule,
  getArrayRule,
  getEmailRule,
  getPhoneRule,
  getPasswordRule,
  resetFilters,
  getFormInitialValues,
  processNewFormValues,
  getDiffInfo,
  getShouldUpdate,
  getShouldUpdateByNamePath,
  getConfigValidateMessages,
  getCharacterCount,
  getColorRule,
};
