import { t } from "helpers/i18n";
import { lazy } from "react";

import { routeConstants } from "constants/index";

const { PATH } = routeConstants;

//Pages
const PricingTableList = lazy(() => import("containers/PricingTable/PricingTableList"));
const LicensePlanList = lazy(() => import("containers/LicensePlan/LicensePlanList"));

export default [
  // Sub menu
  {
    exact: true,
    path: PATH.APPLICATION_PRICING_TABLE.LIST,
    name: t("PricingTable"),
    component: PricingTableList,
  },
  {
    exact: true,
    path: PATH.APPLICATION_PRICING_TABLE.LICENSE_PLAN,
    name: t("LicensePlan"),
    component: LicensePlanList,
  },
];
