import i18n from "i18n";

export const t = (text: string, options?: object) => i18n.t(text, options);

export const tInputPlaceholder = (text: string, originFormat = false) => {
  return t("EnterPlaceholder", {
    field: originFormat ? t(text) : t(text).toLowerCase(),
  });
};

export const tSelectPlaceholder = (text: string, originFormat = false) =>
  t("SelectPlaceholder", {
    field: originFormat ? t(text) : t(text).toLowerCase(),
  });

export const tFieldIsRequired = (text: string) =>
  t("FieldIsRequired", {
    field: t(text),
  });
