export default {
  SKU_REGEX: /^[a-zA-Z\d_.\-/]+$/,
  BARCODE_REGEX: /^[a-zA-Z0-9.-]*$/,
  NUMBER_THOUSAND_SEPARATOR_REGEX: /[^0-9,-]/g,
  COUPON_REGEX: /^[a-zA-Z0-9]*$/,
  SEPARATOR_REGEX: /[\n\s;:,./\\]+/,
  SKU_SEPARATOR_REGEX: /[\s;:,]+/,
  ALPHABET_REGEX: /^[A-Za-z0-9]+$/,
  CATEGORY_CODE_REGEX: /^[a-zA-Z\d_.-]+$/,
  PROVIDER_CODE_REGEX: /^[a-zA-Z]+$/,
  BRAND_CODE_REGEX: /(^[a-z\d][-a-z\d]*[a-z\d]$)|(^[a-z\d]$)/g,
  MARGIN_REGEX: /^[0-9]+.{0,1}[0-9]*$/,
  EMAIL_REGEX: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
  PHONE_REGEX: /^(\+84|84|0)(3|5|7|8|9|2[0-9])([0-9]{8})$\b/g,
  SLUG_REGEX: /^[a-zA-Z0-9ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ-]*$/,
  SHIPPING_TYPE_CODE_REGEX: /^[A-Z_]*$/,
  NATURAL_NUMBER_REGEX: /^[0-9]*$/,
  POSITIVE_INTEGER_REGEX: /^[1-9]\d*$/,
  HEX_COLOR_REGEX: /^#[0-9a-fA-F]{6}$/,
  KEYWORD_REGEX: /^[a-zA-Z0-9ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ ]*$/,
  SYNONYM_SLANG_REGEX: /^[a-zA-Z0-9ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\-_ ]*$/,
  HTML_REGEX: /<br>(?=(?:\s*<[^>]*>)*$)|(<br>)|<[^>]*>/gi,
  ACCOUNT_BANK_HOLDER_REGEX: /^[\w ]*$/,
  ACCOUNT_BANK_NUMBER_REGEX: /^[\w\d]*$/,
  OFFLINE_PAYMENT_METHOD_NAME_REGEX: /^[a-zA-Z0-9ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\-_ ]+$/,
};
