import {
  IGetListLicensePlanResponse,
  ILicensePlanFilters,
  ILicensePlanCreate,
  ILicensePlanUpdate,
} from "interfaces/licensePlan";
import { requestServices } from "services/index";

const { marketCoreClient } = requestServices;

const getListLicensePlan = (params: ILicensePlanFilters): Promise<IGetListLicensePlanResponse> => {
  return marketCoreClient.get("/api/v2/license-plans", {
    params,
  });
};

const createLicensePlan = (data: ILicensePlanCreate) => {
  return marketCoreClient.post("/api/v2/license-plans", data);
};

const updateLicensePlan = (data: ILicensePlanUpdate) => {
  const { id, ...rest } = data;
  return marketCoreClient.patch(`/api/v2/license-plans/${id}`, rest);
};

export default {
  getListLicensePlan,
  createLicensePlan,
  updateLicensePlan,
};
