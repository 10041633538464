/* eslint-disable @typescript-eslint/no-unused-vars */
import { PaginationProps } from "antd";
import { commonConstants } from "constants/index";
import { IPagination } from "interfaces";
import { ILicensePlan, ILicensePlanFilters } from "interfaces/licensePlan";
import { useEffect, useState } from "react";
import { licensePlansServices } from "services";

const { DEFAULT_PAGINATION, DEFAULT_SIZE_CHANGER } = commonConstants;

const useListLicensePlansData = (defaultParams: ILicensePlanFilters) => {
  const [queryParams, setQueryParams] = useState(defaultParams);
  const [fetching, setFetching] = useState(false);
  const [licensePlans, setLicensePlans] = useState<ILicensePlan[]>([]);
  const [pagination, setPagination] = useState<IPagination>({
    ...DEFAULT_PAGINATION,
    ...DEFAULT_SIZE_CHANGER,
  });

  const getLicensePlan = async (params: ILicensePlanFilters) => {
    try {
      setFetching(true);
      const res = await licensePlansServices.getListLicensePlan(params);
      // const res = await licensePlanMockServices.getListLicensePlan(params);
      if (res.data) {
        const { data } = res.data;
        const {
          pagination: { page, pageSize, total },
          licensePlans,
        } = data;
        setLicensePlans(licensePlans || []);
        setPagination({
          ...pagination,
          current: page,
          pageSize,
          total,
        });
      }
    } finally {
      setFetching(false);
    }
  };

  const handleTableChange = (pager: PaginationProps) => {
    const { current, pageSize } = pager;
    const newQueryParams = {
      ...queryParams,
      page: current,
      pageSize,
    };
    setQueryParams(newQueryParams);
  };

  useEffect(() => {
    if (queryParams && queryParams.appId) {
      getLicensePlan(queryParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const handleRefresh = () => setQueryParams(prev => ({ ...prev, page: 1 }));

  return {
    queryParams,
    setQueryParams,
    licensePlans,
    fetching,
    handleRefresh,
    pagination,
    handleTableChange,
  };
};

export default {
  useListLicensePlansData,
};
